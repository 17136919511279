<template>
	<div>
		<div style="position: relative;">
			<div class="reschouImg" :style="{backgroundImage:'url('+IndexData.large+')'}"></div>
			<!-- <div class="bannerOnTxt wow fadeInRight">
				案例中心
			</div> -->
		</div>
		<div class="width80 margin0Auto mobleiWidth">
			<div class="caseBox">
				<div class="CaseCenter">
					<div class="caseItem " :class="{'active':index==selectIndex}" @click="selectCase(index)"
						v-for="(data,index) in IndexData.product_service" data-wow-delay='0.1s'>{{data.name}}</div>
				</div>
			</div>
			<div class="caseBoxList">
				<el-row :gutter="30" class="row-bg" style="width: 100%;">
					<el-col :xs="24" :sm="12" :md="12" :lg="8" v-for="(data,index) in caseList" :key="index">
						<div class="caseChilden wow fadeInUp" @click="toDetail(data.id)">
							<!-- <img class="caseImg" :src="data.cover" alt=""> -->
							<div  class="caseImg"  :style="{backgroundImage:'url('+data.cover+')'}"></div>
							<div class="proCase">
								<div>
									<p class="mti">{{data.name}}</p>
									<p class="fti">{{data.desc}}</p>
								</div>
								<img class="iconjiantou" src="@/assets/iconjiantou.png" alt="">
							</div>
						</div>
					</el-col>
				</el-row>
				<!-- ani_openDtween -->
				<div class="paginat_box">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="pageInfo.current_page" :page-size="pageInfo.per_page"
						layout="total, prev, pager, next" :total="pageInfo.total">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		get_case_list
	} from "@/api/apis";
	import WOW from "wow.js";
	import "wow.js/css/libs/animate.css";
	export default {
		name: 'caseIntroduct',
		data() {
			return {
				currentPage: 1,
				pagesize: 3,
				totalNumber: 10,
				IndexData: {},
				caseList: [],
				pageInfo: {
					current_page:1
				},
				selectIndex: 0,
				category_id: ''
			}
		},
		beforeRouteEnter(to, from, next){
			if(from.name === 'Index'||from.name === 'companyProfil'||from.name === 'About') { //判断是从哪个路由过来的，若是detail页面不需要刷新获取新数据，直接用之前缓存的数据即可
			  window.scrollTo(0,0) 
		    }
			next();
		},
		activated(){
			this.indexData();
		},
		mounted() {},
		methods: {
			selectCase(index) {
				this.currentPage = 1;
				this.selectIndex = index;
				this.category_id = this.IndexData.product_service[index].id;
				this.indexData();
			},
			handleSizeChange() {},
			handleCurrentChange(e) {
				this.currentPage = e;
				this.indexData();
			},
			async indexData() {
				let {
					data
				} = await get_case_list({
					category_id: this.category_id,
					page: this.currentPage
				});
				this.IndexData = data.data;
				let sid = this.IndexData.product_service[0].id;

				this.pageInfo = this.IndexData.case_list;
				this.caseList = this.IndexData.case_list.data;
				
				this.$nextTick(() => {
					let wow = new WOW({
						boxClass: "wow", // animated element css class (default is wow)
						animateClass: "animated", // animation css class (default is animated)
						offset: 0, // distance to the element when triggering the animation (default is 0)
						mobile: true, // trigger animations on mobile devices (default is true)
						live: false, // act on asynchronously loaded content (default is true)
						callback: function(box) {
							
							// the callback is fired every time an animation is started
							// the argument that is passed in is the DOM node being animated
						}
					});
					wow.init()
				});
			},
			toDetail(value) {
				this.$router.push({ path: '/detail', query: { value: value } })
			}
		}
	}

</script>

<style>
	@import url("../style/main.css");

	.paginat_box {
		margin: 30px auto;
		display: table;
		margin-bottom: 30px;
	}

	.el-pager li {
		border: 1px solid #f1f1f1;
		margin: 0 6px;
	}

</style>

