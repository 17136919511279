<template>
	<div>
		<div style="position: relative;">
			<div class="reschouImg" v-if="info&&info.large" :style="{backgroundImage:'url('+info.large+')'}"></div>
			<!-- <div class="bannerOnTxt wow fadeInRight">
				公司简介
			</div> -->
		</div>
		<div class="introduct_box mobleiWidth companyJianjie">
			<el-row :gutter="0" class="introductRow" style="width: 100%;">
				<el-col :xs="24" :sm="24" :md="12" :lg="12" class="wow fadeInLeft">
					<img class="iImg"  v-if="info&&info.img" :src="info.img" alt="">
				</el-col>
				<el-col :xs="24" :sm="24" :md="12" :lg="12"  class="wow fadeInRight">
					<div class="introInfo ">
						<p class="ti1  wow fadeInUp" >ABOUT COSEAST</p>
						<p class="ti2  wow fadeInUp">昱普生简介</p>
						<p class="ti3  wow fadeInUp" v-if="info&&info.content" v-html="info.content"></p>
						<div class="certificateList">
							<div class="ceItem"  v-for="data in info.achievement_list">
								<div class="ceBox" style="display:block">
									<p class="num">{{data.value}}</p>
									<p class="cname">{{data.name}}</p>
								</div>
							</div>
							<!-- <div class="ceItem">
							<p class="num">ISO9001</p>
							<p class="cname">质量体系认证</p>
						</div>
						<div class="ceItem">
							<p class="num">双软认证</p>
							<p class="cname">	</p>
						</div> -->
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<p class="aboutTitle at wow fadeInUp">服务流程</p>
		<div class="Serviceprocess pc_ervice mobleiWidth wow fadeInUp">

			<div v-for="(data,index) in info.service_process" style="display: flex;">
				<div class="sItem">
					<img class="processImg" :src="data.img" alt="">
					<p>{{data.name}}</p>
				</div>
				<img class="jiantArrow" v-if="index!=info.service_process.length - 1 " src="@/assets/jiantArrow.png"
					alt="">
			</div>

		</div>
		<p class="aboutTitle at" style="padding-bottom: 20px;">产品与服务</p>
		<div style="width:80%;margin:0 auto" class="mobleiWidth">
			<div class="productServer">
				<el-row :gutter="12" class="row-bg" style="width: 100%;">
					<el-col :xs="24" :sm="24" :md="12" :lg="12" v-for="(data,index) in perviceInfoData.product_service">
						<li class="li_pitem wow fadeInUp">
							<div class="li_con">
								<div class="simgs">
									<img class="simgs simgs_active" :src="data.img" alt="">
								</div>
								<div class="productInfo">
									<p class="xti">{{data.name}}</p>
									<p class="xmus">{{data.desc}}</p>
									<p class="xgong">{{data.lable}}</p>
									<div class="add_look ">
										<span @click="toDetail(data.id)" style="position: relative;z-index: 9;">查看详情</span>
									</div>
								</div>
							</div>
						</li>
					</el-col>
				</el-row>
			</div>
		</div>
		<freeEvaluat></freeEvaluat>
		<Footer></Footer>
	</div>
</template>
<script>
	import Footer from '@/components/footer'
	import freeEvaluat from '@/components/freeEvaluat'
	import WOW from "wow.js";
	import "wow.js/css/libs/animate.css";

	import {
		get_company_desc,
		get_home_page_data
	} from "@/api/apis";
	export default {
		components: {
			Footer,
			freeEvaluat
		},
		
		beforeRouteEnter(to, from, next){
			next();
			
			if(from.name === 'Index'||from.name === 'caseIntroduct'||from.name === 'About') { //判断是从哪个路由过来的，若是detail页面不需要刷新获取新数据，直接用之前缓存的数据即可
			  window.scrollTo(0,0) 
			}
			
		},
		data() {
			return {
				info: {},
				perviceInfoData: {}
			}
		},
		activated(){
			this.indexData();
		},
		mounted() {
			this.getProviceData();
		},
		methods: {
			toDetail(value) {
				this.$router.push({ path: '/productService', query: { value: value } })
			},

			async getProviceData() {
				let {
					data
				} = await get_home_page_data();
				this.perviceInfoData = data.data;
			},
			async indexData() {
				let {
					data
				} = await get_company_desc();
				this.info = data.data;
				this.$nextTick(() => {
					let wow = new WOW({
						boxClass: "wow", // animated element css class (default is wow)
						animateClass: "animated", // animation css class (default is animated)
						offset: 0, // distance to the element when triggering the animation (default is 0)
						mobile: true, // trigger animations on mobile devices (default is true)
						live: false, // act on asynchronously loaded content (default is true)
						callback: function(box) {
							
							// the callback is fired every time an animation is started
							// the argument that is passed in is the DOM node being animated
						}
					});
					wow.init()
				});
			}
		}
	}

</script>

<style>
	@import url("../style/main.css");

	.Serviceprocess {
		display: flex;
		justify-content: center;
	}

	.Serviceprocess p {
		margin-top: 7px;
		font-size: 14px;
	}

	.Serviceprocess .processImg {
		width: 78px;
		height: 78px
	}

	.Serviceprocess .sItem {
		text-align: center;
	}

	.Serviceprocess .sItem p {
		font-size: 16px;
	}

	.jiantArrow {
		width: 36px;
		height: 20px;
		margin: 0 14px;
		margin-top: 23px;
	}

	.qualificatCom {
		width: 90%;
		margin: 0 auto;
	}

	.qualificatCom .rongziImg {
		width: 100%;
		height: 300px;
		padding: 15px 0;
	}

	.qualificatBox {
		width: 100%;
		background: #EFF3F7;
	}

	.aboutTitle {
		color: #333333;
		font-size: 29px;
		text-align: center;
		padding: 40px 0;
		padding-bottom: 70px;
	}

	.at {
		margin-top: 83px;
	}

	.certificateList {
		display: flex;
		flex-wrap: wrap;
		margin-top: 30px;
	}
	.certificateList .ceItem {
		width: 33.3%;
		margin-bottom:20px;
	}
	.certificateList .ceBox {
		text-align: center;
	}

	.certificateList .num {
		color: #333333;
		font-size: 26px;
	}

	.certificateList .cname {
		color: #999999;
		font-size: 12px;
		margin-top: 6px;
	}

	.introductRow {
		padding: 50px 0;
	}

	.introduct_box {
		width: 80%;
		margin: 0 auto;
	}

	.introduct_box .iImg {
		width: 100%;
		height: 3rem;
	}

	.introInfo {
		padding-left: 30px;
	}

	.introInfo .ti1 {
		font-size: 15px;
		color: #444;
	}

	.introInfo .ti2 {
		font-size: 20px;
		color: #333333;
		margin-top: 8px
	}

	.introInfo .ti3 {
		font-size: 14px;
		color: #999;
		line-height: 24px;
		margin-top: 13px
	}

</style>

