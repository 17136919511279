import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

router.beforeEach((to, from, next) => {
	
	next();
   //已经 存在token  name 就可以进行  
  // next();  
  // let headClick  = localStorage.getItem('headClick');
  // if(headClick==1){
  // 	window.scrollTo(0,0)
  // }
  // localStorage.setItem('headClick',0)
});

new Vue({
	router,
  render: h => h(App),
}).$mount('#app')
