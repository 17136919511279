import axios from "axios";

import local from "@/utils/local";
import { Message } from "element-ui";
axios.defaults.baseURL = "https://sdapi.epzen.com/"; // 服务器请求地址
axios.defaults.withCredentials = false;
     
//响应拦截器
axios.interceptors.response.use((response) => {
	let {
		sta,
		msg
	} = response.data;
	
	return response;
},);
// 封装通用的get请求和post请求
export default {
	get(url, data = {}) {
		return new Promise((resolve, reject) => {
			axios
				.get(url, {
					params: data,
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	post(url, data = {}) {
		var userinfo = local.get('userinfo')
		// data.token = "34b7866466ec6843446c0fa23d71cc3ff2e753e1"
		// data.user_id = "1620"
		if (userinfo) {
			// data.token = userinfo.token
			// data.user_id = userinfo.id
		}
		return new Promise((resolve, reject) => {
			axios
				.post(url, data)
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
};
