<template>
	<div class="freeEvaluat">
		<div class="mainCon">
			<el-row :gutter="12" class="row-bg" style="width: 100%;">
				<el-col :xs="24" :sm="24" :md="12" :lg="12" >
					<img class="groupbah" src="@/assets/groupbah.png" alt="">
				</el-col>
				<el-col :xs="24" :sm="24" :md="12" :lg="12" >
					<div class="freeForm">
						<p class="frerTi">免费评估需求，获取报价方案</p>
						<div class="formItem">
							<!-- <el-input type="text" </el-input> -->
							<input type="text" v-model="paramInfo.company" class="ipt" placeholder="请输入您的公司名称（选填）">
						</div>
						<div class="formItem">
							<input type="text"  v-model="paramInfo.name" class="ipt" placeholder="请输入您的称呼">
							<span class="bitian" v-if="paramInfo.name.length==0">（必填）</span>
						</div>
						<div class="formItem">
							<input type="text"  v-model="paramInfo.phone" class="ipt" placeholder="请输入手机号码">
							<span class="bitian"  v-if="paramInfo.phone.length==0">（必填）</span>
						</div>
						<div class="formItem">
							<textarea name="" id="" cols="10" rows="3" v-model="paramInfo.content" class="textarea"  placeholder="请输入您的需求概述（选填）" autosize></textarea>
						</div>
						<div class="nowAppointment" @click="sub">
							立即预约
						</div>
					</div>
				</el-col>
			</el-row>
			
			
		</div>
	</div>
</template>

<script>
	import {
		get_config_data,
		set_needs
	} from "@/api/apis";
	export default{
		data(){
			return{
				isshowTiam:true,
				msg:'',
				paramInfo:{
					company:'',
					name:'',
					phone:'',
					content:''
				}
			}
		},
		methods:{
			handelfocus(){
				this.isshowTiam = false;
				setTimeout(()=>{
					this.isshowTiam = false;
				},200)
			},
			handelBlur(){
				setTimeout(()=>{
					this.isshowTiam = true;
				},200)
			},
			async sub(){
				if(!this.paramInfo.name){
					
					this.$message({
						message: '请输入您的称呼!',
						type: "info",
					});
					
					return false
				}
				if(!this.paramInfo.phone){
					
					this.$message({
						message: '请输入手机号!',
						type: "info",
					});
					return false
				}
				if(this.paramInfo.phone){
					var regPhone = /^1[3456789]{1}\d{9}$/; //手机正则
					if(!regPhone.test(this.paramInfo.phone)){
						this.$message({
							message: '请输入正确的手机号!',
							type: "info",
						});
						return false
					}
				}
				let {
					data
				} = await set_needs(this.paramInfo);
				
				if(data.code==1){
					this.$message({
						message: '提交成功，我们尽快和您联系！',
						type: "success",
					});
					this.paramInfo.company = '';
					this.paramInfo.name = '';
					this.paramInfo.phone = '';
					this.paramInfo.content = '';
				}else{
					this.$message({
						message: data.msg,
						type: "error",
					});
				}
			}
		}
	}
</script>

<style>
	@import url("../style/main.css");
</style>