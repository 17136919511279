export default {
  get(key) {
    return JSON.parse(localStorage.getItem(key));
  },
  set(key, val) {
    localStorage.setItem(key, JSON.stringify(val));
  },
  sessionSet(key, val){
    sessionStorage.setItem(key, JSON.stringify(val));
  },
  sessionGet(key){
    return JSON.parse(sessionStorage.getItem(key));
  },
  remove(key) {
    localStorage.removeItem(key);
  },
  clear() {
    localStorage.clear();
  },
};
