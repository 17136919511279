<template>
	<div>
		<!-- rotateInUpRight rotateInUpLeft bounceInRight bounceInUp fadeInUp -->
		<div class="bannerQuyu" style="position: fixed;top:0;left:0;width:100%;overflow-x: hidden;" :style="{'height':isMoble?'4rem':'calc(100vh)'}" >
			<el-carousel :height="isMoble?'4rem':'calc(100vh)'" v-if="IndexData.banner_list&&IndexData.banner_list.length>0" class="pcswpier" :interval="interval" :autoplay="true" @change="swiperchange">
				<el-carousel-item class="bannerimg"  v-for="(data,index) in IndexData.banner_list">
					<div class="bannerimg"  :style="{'height':isMoble?'4rem':'calc(100vh)','backgroundImage':'url('+data.img+')'}" ></div>
					<!-- <img class="bannerimg" :src="data.img" style="width: 100%;" alt=""> -->
					<div class="textmoduleBox" :style="{'height':isMoble?'4rem':'calc(100vh)'}" >
						<div class=" textmodeul" v-if="index==0"> 
							<p class="title ms_ani" :class="{'bantxt_ani0':isCarousel}"><span style="color:#95FFDF">16</span>年来不断更新迭代<br/>我们的技术与服务<br/></p>
							<p class="ms ms_ani" :class="{'bantxt_ani1':isCarousel}">整合企业应用场景和业务需求，打造个性化的解决<br/>方案，为企业创造价值</p>
							<div class="nowZixBtn wow fadeInUp" @click="openZixun">立即咨询</div>
						</div>
						<div class=" textmodeul" v-if="index==1">
							<p class="title startWeiz1" :class="{'bantxt_ani2':isCarousel}">拥有<span style="color:#95FFDF">7</span>年的互联网应用<br/>定制经验</p>
							<p class="ms startWeiz1" :class="{'bantxt_ani3':isCarousel}">我们不断为您优化方案，产品研发，用户体验和<br/>项目管理的品质与服务</p>
							<div class="nowZixBtn wow fadeInUp" style="background-color: #24C8DE;" @click="openZixun">立即咨询</div>
						</div>
						<div class=" textmodeul" v-if="index==2">
							<p class="title startWeiz2" :class="{'bantxt_ani4':isCarousel}">我们一直专注于提供<br/>互联网<span style="color:#95FFDF">应用定制</span>服务</p>
							<p class="ms startWeiz2" :class="{'bantxt_ani5':isCarousel}">是众多大型企业事业单位、集团和上市公司的长期 合作伙伴</p>
						</div>
					</div>
				</el-carousel-item>
				<div class="downImgBox" @click="scrollTo">
					<div class="dImg"></div>  
				</div>
			</el-carousel>
		<!-- 	<el-carousel height="4rem" class="mobleswpier" :interval="interval" :autoplay="true" @change="swiperchange1">
				<el-carousel-item class="bannerimg" v-for="(data,index) in IndexData.banner_list">
					<div class="bannerimg" style=" height:4rem ":style="{backgroundImage:'url('+data.img+')'}"></div>
					<div class="textmoduleBox" style="height:4rem">
						<div class=" textmodeul" v-if="index==0">
							<p class="title ms_ani" :class="{'bantxt_ani0':isCarouselPhone}">我们一直专注于提供<br/>互联网<span style="color:#95FFDF">应用定制</span>服务</p>
							<p class="ms ms_ani" :class="{'bantxt_ani1':isCarouselPhone}">是众多大型企业事业单位、集团和上市公司的长期合作伙伴</p>
						</div>
						<div class=" textmodeul" v-if="index==1">
							<p class="title startWeiz1" :class="{'bantxt_ani2':isCarouselPhone}">我们一直专注于提供<br/>互联网<span style="color:#95FFDF">应用定制</span>服务</p>
							<p class="ms startWeiz1" :class="{'bantxt_ani3':isCarouselPhone}">是众多大型企业事业单位、集团和上市公司的长期 合作伙伴</p>
						</div>
						<div class=" textmodeul" v-if="index==2">
							<p class="title startWeiz2" :class="{'bantxt_ani4':isCarouselPhone}">我们一直专注于提供<br/>互联网<span style="color:#95FFDF">应用定制</span>服务</p>
							<p class="ms startWeiz2" :class="{'bantxt_ani5':isCarouselPhone}">是众多大型企业事业单位、集团和上市公司的长期 合作伙伴</p>
						</div>
					</div>
				</el-carousel-item>
				<div class="downImgBox" @click="scrollTo">
					<div class="dImg"></div>  
				</div>
			</el-carousel> -->
		</div>
		<div class="diay_sheqquy" style="width:100%"  :style="{'height':isMoble?'4rem':'calc(100vh - 70px)'}"></div>
		<div style="position:relative;z-index:99;background-color: #fff;">
			<div class="customDevelopment">
				<div style="height:1px;width:100%"></div>
				<p class="cuTitle wow fadeInUp" data-wow-offset="20" style="margin-top:68px">
					全生态化定制开发
				</p>
				<p class="cums wow fadeInUp" data-wow-offset="10">
					整体行业定制方案，驱动企业互联网化
				</p>
				<div class="customDeve_list wow fadeInUp">
					<div class="c_item  " data-wow-duration='0.3s' v-for="it in IndexData.icon_list">
						<div class="custom_open">
							<img class="custom_cimg weijih" :src="it.img" alt="">
							<img class="custom_cimg weijih_active" :src="it.effect" alt="">
						</div>
						<p class="ctxt">{{it.desc}}</p>
					</div>
				</div>
			</div>
			<div class="productServeBox" style="margin-top:130px">
				<div class="modal">
					<div class="width80 margin0Auto mobleiWidth" style="overflow: hidden;">
						<p class="cuTitle wow fadeInUp" style="margin-top:60px;color:#fff">
							产品与服务
						</p>
						<div class="productServer wow fadeInUp" style="margin-top:60px">
							<el-row :gutter="20" class="row-bg "  style="width: 100%;">
								<el-col :xs="24" :sm="24" :md="12" :lg="12" v-for="(data,index) in IndexData.product_service">
									<li class="li_pitem "  data-wow-duration='0.3s'  >
										<div class="li_con">
											<div class="simgs">
												<img class="simgs simgs_active" :src="data.img" alt="">
											</div>
											<div class="productInfo">
												<p class="xti">{{data.name}}</p>
												<p class="xmus">{{data.desc}}</p>
												<p class="xgong">{{data.lable}}</p>
												<div class="add_look " @click="toDetail(data.id)">
													<span style="position: relative;z-index: 9;">查看详情</span>
												</div>
											</div>
										</div>
									</li>
								</el-col>
							</el-row>
						</div>
					</div>
				</div>
			</div>
			
			<p class="cuTitle wow bounceInLeft" data-wow-offset="-20">
				案例中心
			</p>
			<div class="width80 margin0Auto mobleiWidth" >
				<div class="caseBox">
					<div class="CaseCenter wow fadeInUp">
						<div class="caseItem  wow fadeInUp" :class="{'active':caseSelectIndex==index}" @click="changeCase(index)" v-for="(data,index) in IndexData.case_list"  :data-wow-delay="'0.'+index+'s'" data-wow-delay='0.1s'>{{data.name}}</div>
						<!-- <div class="caseItem  wow fadeInUp" data-wow-delay='0.4s'>小程序</div>
						<div class="caseItem  wow fadeInUp" data-wow-delay='0.6s'>大数据产品</div>
						<div class="caseItem  wow fadeInUp" data-wow-delay='0.8s'>电商平台</div>
						<div class="caseItem  wow fadeInUp" data-wow-delay='1s'>网站开发</div> -->
					</div>
				</div>
				<div class="caseBoxList wow fadeInUp">
					<el-row :gutter="30" class="row-bg" style="width: 100%;">
						<el-col :xs="24" :sm="12" :md="12" :lg="8" v-for="(data,index) in caseList" >
							<div class="caseChilden wow fadeInUp" @click="toCaseDetail(data.id)"  :data-wow-delay="'0.'+index+'s'">
								<div  class="caseImg"  :style="{backgroundImage:'url('+data.cover+')'}"></div>
								<div class="proCase">
									<div>
										<p class="mti">{{data.name}}</p>
										<p class="fti">{{data.desc}}</p>
									</div>
									<img class="iconjiantou" src="@/assets/iconjiantou.png" alt="">
								</div>
							</div>
						</el-col>
					</el-row>
					<!-- ani_openDtween -->
					<div class="casebtn wow fadeInUp" @click="moreCase">
						<span class="txt1">查看更多</span>
					</div>
				</div>
			</div>
			<div class="reruanzhuqImg">
				<div class="mainCon">
					<div class="width80 margin0Auto wow bounceInUp">
						<el-row :gutter="0" class="row-bg" style="width: 100%;">
							<el-col class="reruanzhuqItem halfreruanzhuqItem" :xs="12" :sm="12" :md="12" :lg="8" v-for="(data,index) in IndexData.achievement_list">
								<div class="gutter_item" :style="{borderRight:(index + 1)%3==0?0:''}">
									<p class="num">{{data.value}}</p>
									<p class="customtxt">{{data.name}}</p>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
			<div class="width80 margin0Auto">
				<p class="cuTitle wow fadeInUp">
					我们的客户
				</p>
				<div class="ourCustomers wow fadeInUp" data-wow-duration='2s'>
					<el-row :gutter="20" class="row-bg" style="width: 100%;">
						<el-col :xs="12" :sm="12" :md="4" :lg="4" v-for="data in IndexData.connection_list">
							<div class="ourCustomersList">
								<div class="container"> 
									<img class="customerImg front" :src="data.img" alt="">
									<img class="customerImg back" :src="data.effect" alt="">
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<freeEvaluat></freeEvaluat>
			<Footer></Footer>
			<customer ref="customerRef"></customer>
		</div>
		

		
	</div>
</template>

<script>
	import Swiper from "swiper"
	import "swiper/swiper.css"; //样式文件
	import "swiper/swiper-bundle.css"; //样式文件
	import WOW from "wow.js";
	import "wow.js/css/libs/animate.css";
	import {
		get_home_page_data
	} from "@/api/apis";
	import Header from '@/components/header'
	import Footer from '@/components/footer'
	import customer from '@/components/customer'
	import freeEvaluat from '@/components/freeEvaluat'
	export default {
		components: {
			Footer,
			customer,
			freeEvaluat
		},
		data() {
			return {
				isshowTop:false,
				interval: 5000,
				caseList:[],
				caseSelectIndex:0,
				isMoble:false,
				isCarousel: false,
				isCarouselPhone: false,
				IndexData:{}
			}
		},
		beforeRouteEnter(to, from, next){
			
			if(from.name === 'companyProfil'||from.name === 'caseIntroduct'||from.name === 'About') { //判断是从哪个路由过来的，若是detail页面不需要刷新获取新数据，直接用之前缓存的数据即可
			  window.scrollTo(0,0) 
		    }
			next();
		},
		activated(){
			this.isCarousel = false;
			this.isCarouselPhone = false;
			this.indexData();
		},
		mounted() {
			this.getModel();
		},
		methods: {
			getModel(){
				if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
				    this.isMoble = true;
				} else if (/(Android)/i.test(navigator.userAgent)) {
				    this.isMoble = true;
				} else {
				    this.isMoble = false;
				};
			},
			openZixun(){
				this.$refs.customerRef.openRef();
			},
			moreCase(){
				this.$router.push({path:'/caseIntroduct'})
			},
			changeCase(index){
				this.caseSelectIndex = index;
				this.caseList =  this.IndexData.case_list[index].case_list
			},
			toDetail(value){
				localStorage.setItem('headClick',1)
				this.$router.push({path:'/productService',query: {value:value}})
			},
			toCaseDetail(value){
				this.$router.push({path:'/detail',query: {value:value}})
			},
			scrollTo() {
				let height = window.screen.availHeight - 50;
				window.scrollTo({
					top: height,
					left: 0,
					behavior: "smooth"
				});
			},
			scrollToTop() {
				let height = window.screen.availHeight - 150;
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: "smooth"
				});
			},
			swiperchange(e) {
				
				this.isCarousel = false;
				setTimeout(() => {
					this.isCarousel = true;
				}, 50)
			},
			swiperchange1(){
				this.isCarouselPhone = false;
				setTimeout(() => {
					this.isCarouselPhone = true;
				}, 50)
			},
			async indexData() {
				let {
					data
				} = await get_home_page_data();
				this.IndexData = data.data;
				this.caseList =  this.IndexData.case_list[0].case_list
				setTimeout(() => {
					this.isCarousel = true;
					this.isCarouselPhone = true;
				}, 100)
				this.$nextTick(() => {
					let wow = new WOW({
						boxClass: "wow", // animated element css class (default is wow)
						animateClass: "animated", // animation css class (default is animated)
						offset: 0, // distance to the element when triggering the animation (default is 0)
						mobile: true, // trigger animations on mobile devices (default is true)
						live: false, // act on asynchronously loaded content (default is true)
						callback: function(box) {
							// the callback is fired every time an animation is started
							// the argument that is passed in is the DOM node being animated
						}
					});
					wow.init()
				});
			}
		}
	}
</script>
<style >
	@import url("../style/main.css");
	.nowZixBtn{
		width:160px;
		height:35px;
		text-align: center;
		line-height:35px;
		color: #fff;
		font-size:14px;
		border-radius:6px;
		background-color: #3A98FF;
		margin-top: 35px;
		cursor: pointer;
		opacity: .9;
		transition: all 0.2s ease-out 0s;
	}
	.nowZixBtn:hover{
		opacity: 1;
		transition: all 0.2s ease-out 0s;
	}
	.nowZixBtn:hover{
		cursor: pointer;
	}
</style>
