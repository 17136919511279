<template>
	<div>
		<div v-if="activedUrl =='/'">
			<Header></Header>
		</div>
		<div v-else>
			<HeaderOth></HeaderOth>
		</div>
		
		<keep-alive>
		    <!-- 需要缓存的视图组件 -->
		  <router-view v-if="$route.meta.keepAlive">
		  </router-view>
		</keep-alive>
		<!-- 不需要缓存的视图组件 -->
		<router-view v-if="!$route.meta.keepAlive">
		</router-view>
		<!-- <router-view ></router-view> -->
		<!-- <router-view v-if="!$route.meta.keepAlive"></router-view> -->
	</div>
</template>
<script>
	import Header from '@/components/header'
	import HeaderOth from '@/components/HeaderOth'
	import Footer from '@/components/footer'
	export default{
		components:{
			Header,
			Footer,
			HeaderOth
		},
		data(){
			return{
				activedUrl:'/'
			}
		},
		created(){
			window.addEventListener("scroll", this.scrolling);
			let activedUrl = localStorage.getItem('activedUrl')||'';
			if(activedUrl){
				this.activedUrl = activedUrl
			}
		},
		watch: {
			$route: {
				handler: function(val, oldVal) {
					// navactive		
					localStorage.setItem('activedUrl',this.$route.fullPath)
					this.activedUrl = this.$route.fullPath;
					
				},
				// 深度观察监听
				deep: true,
			},
		},
	}
</script>
<style>
	@import url("style/media.css");
	body,html{
		font: 12px Arial, "微软雅黑";
		    -webkit-transition: 0.5s;
		    transition: 0.5s;
	}
	body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, dl, dt, dd, ul, ol, li,
	pre, form, fieldset, legend, button, input, textarea, th, td {
		margin: 0;
		padding: 0
	}
	body, button, input, select, textarea {
		font: 13px "microsoft yahei";
		-ms-overflow-style: scrollbar
	}
	ul, ol,li {
	list-style: none
	}
	a {
		text-decoration: none;
		cursor:pointer
	}
	
	img {
		border: 0
	}
	
	table {
	border-collapse: collapse;
	border-spacing: 0
	}
	.clear {
	clear:both
	}
	.fr {
	float:right
	}
	.fl {
	float:left
	}
	.block {
		display:block;
	}
	.spaceBetween{
		display: flex;
		justify-content: space-between;
	}
	.width1200{
		width:1200px;
	}
	.margin0Auto{
		margin:0 auto
	}
	
	
</style>