<template>
	<div style="background-color: #fff">
		<img class="banzhuquanli" src="@/assets/banzhuquanli.png" alt="">
		<div class="banbox width80 margin0Auto">
			<div class="infoBoxs">
				<div class="" style="flex: 1;">
					<p class="baoti">{{IndexData.detail_data.name}}</p>
					
					<!-- <div class="code_mask">
						<div class="c_item">
							<img class="code_img" src="@/assets/banzhuquanli.png" alt="">
							<p>欢迎扫码体验</p>
						</div>
						<div class="c_item">
							<img class="code_img" src="@/assets/banzhuquanli.png" alt="">
							<p>欢迎扫码体验</p>
						</div>
					</div> -->
				</div>
				<!-- <img class="mendtup" :src="IndexData.detail_data.large" alt=""> -->
			</div>
			<div class="mendtupBoxb"></div>
			<div class="myConter">
				<div class="headline">
					<div class="pjDescribe" style="margin-top: 20px;">{{IndexData.detail_data.content}}</div>
				</div>
				<!--  简介图片-->
				<div class="imgs">
					<el-row :gutter="30" class="row-bg" style="width: 100%;">
						<el-col :xs="24" :sm="12" :md="12" :lg="8" v-for="(data,index) in IndexData.detail_data.imgs"
							:key="data">
							<el-image 
							    class="ig"
							    :src="data" 
							    :preview-src-list="IndexData.detail_data.imgs">
							  </el-image>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>

		<Footer></Footer>
	</div>
</template>

<script>
	import {
		get_case_detail
	} from "@/api/apis";
	import Footer from '@/components/footer'
	export default {
		name: "detail",
		components: {
			Footer,
		},
		data() {
			return {
				IndexData: {}
			}
		},
		created() {
			window.scrollTo(0, 0)

			let id = this.$route.query.value;
			this.indexData(id);
		},
		methods: {
			async indexData(_uid) {
				let {
					data
				} = await get_case_detail({
					case_id: _uid
				});
				this.IndexData = data.data;
			},
		}
	}

</script>

<style scoped>
	.mendtupBoxb{
		background-image: url('../assets/yuanxingbg.png');
		background-position: center;
		background-repeat: no-repeat;
		position: absolute;
		right:0;
		top:0;
		width: 83%;
		height: 100vh;
		z-index: -1;
		background-size: 100%;
	}
	.code_mask{
		display: flex;
		margin-top: 50px;
	}
	.code_mask .c_item{
		margin-right:30px;
		margin-top: 50px;
	}
	.code_mask .c_item p{
		color: #333333;
		font-size: 13px;
		text-align: center;
	}
	.infoBoxs {
		padding: 90px 100px 0 70px;
		display: flex;
		justify-content: space-between;
		
	}
	.code_mask .code_img{
		width:130px;
		height:130px;
	}
	.mendtup{
		width:200px;
		height:419px;
		margin-right:10%;
		box-shadow: 0 0 13px 1px rgba(0,0,0,.1);
		float: right;
	}
	.infoBoxs{
		flex: 1;
	}
	.infoBoxs .baoti {
		color: #333333;
		font-size: 45px;
		/* margin-top:40px; */
		font-weight: bold;
	}
	.infoBoxs .ms {
		color: #666666;
		font-size: 14px;
		margin-top:30px;
		width:80%;
		line-height: 30px;
	}
	.banzhuquanli {
		width: 100%;
	}

	.mybanner img {
		width: 100%;
		height: 10%;
	}

	.banbox {
		margin-top: -40%;
		background-color: #fff;
		position: relative;
		z-index: 4;
	}
	
	.icons {
		width: 88px;
		height: 88px;
	}

	.textBody {
		width: 100%;
		height: calc(100vh);
		padding-left: 5%;
		padding-top: 3%;
		padding-bottom: 3%;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.titles {
		font-weight: bold;
		color: #333333;
		letter-spacing: 2px;
		font-size: 70px;
	}

	.describe {
		font-size: 32px;
		font-weight: 400;
		color: #333333;
		font-family: PingFang SC-Regular, PingFang SC;
		width: 38%;
	}

	.erweimIn {
		width: 206px;
	}

	.erweimIn .erwem {
		width: 206px;
		height: auto;
	}

	.erweimIn .textM {
		font-size: 24px;
		font-weight: bold;
		color: #333333;
		text-align: center;
		margin-top: 6px;
	}

	/*项目简介*/
	.myConter {
		width: 100%;
		height: auto;
		padding: 0px 100px 0 70px;
		box-sizing: border-box;
	}

	.textin {
		font-size: 40px;
		font-family: PingFang SC-Bold, PingFang SC;
		font-weight: bold;
		color: #333333;
	}

	.myte {
		margin-top: 20px;
		margin-bottom: 80px;
	}

	.pjDescribe {
		font-size: 20px;
		font-weight: 400;
		color: #333333;
	}

	.headline {
		margin: 0px 0px 0px 0px;
	}
	.imgs {
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding: 100px 0px;
		box-sizing: border-box;
	}

	.imgs .ig {
		width: 95%;
		height: auto;
		margin:0 auto;
		display: table;
		box-shadow: 0 0 13px 1px rgba(0,0,0,.1);
		margin-bottom: 30px;
	}

</style>

