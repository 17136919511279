<template>
	<div class="footerLun">
		<div >
			<div class="mianCon">
				<el-row :gutter="12" class="row-bg" style="width: 100%;">
					<el-col :xs="24" :sm="24" :md="12" :lg="12">
						<img class="zimg" src="@/assets/blogo_active.png" alt="">
					</el-col>
					<el-col :xs="24" :sm="24" :md="12" :lg="12">
						<div class="footerNav">
							<div class="f_item" @click="toNav(oj)"  v-for="oj in navList">{{oj.name}}</div>
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="xunjiaBox">
				<el-row :gutter="12" class="row-bg" style="width: 100%;">
					<el-col :xs="24" :sm="24" :md="8" :lg="8">
						<div class="zixunPaixun" v-if="info.config_data&&info.config_data.consultation_hotline">
							<p class="ti">咨询热线</p>
							<p class="phone">{{info.config_data.consultation_hotline}}</p>
							<p class="muter">地址：{{info.config_data.company_address}} </p>
							<p class="muter">邮箱：{{info.config_data.company_email}}</p>
						</div>
					</el-col>
					<el-col :xs="24" :sm="24" :md="8" :lg="8">
						<div class="friendlyLink">
							<p class="ti">友情链接</p>
							<div class="xianMu_list">
								<div class="linkItem" @click="openLink(data.link)" v-for="data in info.friendly_link">{{data.title}}</div>
							</div>
						</div>
					</el-col>
					<el-col :xs="24" :sm="24" :md="8" :lg="8">
						<div class="focusUs" >
							<p class="ti">关注我们</p>
							<img class="erweima" v-if="info.config_data&&info.config_data.wechat_code" :src="info.config_data.wechat_code" alt="">
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="banerhao">
			Copyright © 2022. Powered by EyouCms渝ICP备18010345号-2
		</div>
	</div>
</template>

<script>
	import {
		get_config_data
	} from "@/api/apis";
	export default{
		data(){
			return{
				info:{},
				navList:[
					{
						name:'首页',
						url:'/'
					},
					{
						name:'公司简介',
						url:'/companyProfil'
					},
					{
						name:'客户案列',
						url:'/caseIntroduct'
					},
					{
						name:'关于我们',
						url:'/About'
					}
				]
			}
		},
		mounted() {
			this.indexData();
		},
		methods:{
			openLink(url){
				window.open(url,'_blank')
			},
			async indexData() {
				let {
					data
				} = await get_config_data();
				this.info = data.data;
			},
			toNav(data){
				localStorage.setItem('headClick',1);
				localStorage.setItem('activedUrl',data.url);
				this.activedUrl = data.url
				this.$router.push(data.url);
				// this.isshowMobleNav = false;
			},
		}
	}
</script>

<style>
	.banerhao{
		text-align: center;
		border-top: 1px solid #ebebeb; 
		padding: 15px 0;
		color: #999999;
	}
	.focusUs {
		float: right;
		padding-top:40px
	}
	.focusUs .ti{
		color: #666666;
		font-size: 13px;
		text-align: center;
	}
	.focusUs .erweima{
		width:130px; 
		height:130px;
		margin-top: 10px;
	}
	.friendlyLink .ti{
		color: #666666;
		font-size: 16px;
		padding-top:40px
	}
	.friendlyLink .xianMu_list{
		display: flex;
		margin-top:20px;
		flex-wrap: wrap;
	}
	.friendlyLink .linkItem{
		color: #666666;
		font-size: 13px;
		width: 25%;
		margin-bottom: 10px;
	}
	.friendlyLink .linkItem:hover{
		text-decoration: underline;
		cursor: pointer;
		color:#e33c3c;
	}
	.zixunPaixun{
		padding: 60px 0;
	}
	.zixunPaixun .ti{
		color: #666666;
		font-size: 15px;
	}
	.zixunPaixun .phone{
		color: #666666;
		font-size: 17px;
		font-weight: bold;
		margin-top: 5px;
		margin-bottom: 13px;
	}	
	.zixunPaixun .muter{
		color: #666666;
		font-size: 13px;
		margin-top: 7px;
	}
	.footerNav {
		display: flex;
		float: right;
		margin-top:10px;
	}
	.footerNav .f_item{
		font-size: 14px;
		color: #333333;
		margin-left:20px;
	}
	.footerNav .f_item:hover{
		cursor: pointer;
		color:#e33c3c;
		transition: all .5s;
		text-decoration: underline;
	}
	.xunjiaBox{
		width:80%;
		margin:0 auto;
	}
	.footerLun .mianCon{
		width:80%;
		margin:0 auto;
		padding-top:40px;
		display: flex;
	}
	.footerLun .zimg{
		width:80px;
	}
	.footerLun{
		width: 100%;
		/* height: 200px; */
		background-color: #FAFAFA;
	}
</style>