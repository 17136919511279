import request from '@/utils/request'


export function get_home_page_data(params) {
	return request.post("/app/index/get_home_page_data", params);
}

export function get_about_us(params) {
	return request.post("/app/index/get_about_us", params);
}

export function get_company_desc(params) {
	return request.post("/app/index/get_company_desc", params);
}

export function get_config_data(params) {
	return request.post("/app/index/get_config_data", params);
}

export function set_needs(params) {
	return request.post("/app/index/set_needs", params);
}

export function get_case_list(params) {
	return request.post("/app/index/get_case_list", params);
}

export function get_case_detail(params) {
	return request.post("/app/index/get_case_detail", params);
}

export function get_service_detail(params) {
	return request.post("/app/index/get_service_detail", params);
}


