<template>
	<div>
		<div class=" margin0Auto spaceBetween navMian" :class="{'resetheight':isSCroller}">
			<div class="headerNav " :class="{'resetwidth':isSCroller,'homeheaderNav':!isSCroller}">
				<div class="moblieLogo">
					<img class="clogo"  src="@/assets/blogo_active.png" alt="">
				</div>
				<div class="pcLogo">
					<img class="clogo" v-if="!isSCroller"  src="@/assets/blogo.png" alt="">
					<img class="clogo" v-else src="@/assets/blogo_active.png" alt="">
				</div>
				<div class="pcNav">
					<ul class="navBox">
						<li @click="toNav(oj)" :class="{'active':activedUrl==oj.url}" v-for="oj in navList">{{oj.name}}</li>
					</ul> 
				</div>
				<div class="mobelNav">
					<div class="lineBox"  @click="isshowMobleNav = !isshowMobleNav">
						<div class="line"></div>
						<div class="line"></div>
						<div class="line"></div>
					</div>	
				</div>
			</div>
		</div>
		<div style="height:75px;width:100%" v-if="isSCroller"></div>
		<div class="groupbah" style="height:80px;width:100%" v-if="!isSCroller"></div>
		<div class="groupbahd" style="height:60px;width:100%;display: none;" v-if="!isSCroller"></div>
		<div class="mobileNav_modal" :class="{'mobileNavModalActive':isshowMobleNav}" @click="isshowMobleNav = !isshowMobleNav">
		</div>
		<div class="mobileNavList"  :class="{'MobleNavActive':isshowMobleNav}"  @click.stop="">
			<img class="deleteNav" src="@/assets/deleteImg.png" alt="" @click="isshowMobleNav = !isshowMobleNav">
			<div class="NavList_ul">
				<ul>
					<li @click="toNav(oj)" :class="{'active':activedUrl==oj.url}"  v-for="oj in navList">
						{{oj.name}}
					</li>
					
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
	
	export default{
		data(){
			return{
				isshowMobleNav:false,
				isSCroller:false,
				activedUrl:'',
				isHomeUrl:'/',
				navList:[
					{
						name:'首页',
						url:'/'
					},
					{
						name:'公司简介',
						url:'/companyProfil'
					},
					{
						name:'客户案列',
						url:'/caseIntroduct'
					},
					{
						name:'关于我们',
						url:'/About'
					}
				]
			}
		},
		watch: {
			$route: {
				handler: function(val, oldVal) {
					// navactive		
					this.activedUrl = this.$route.fullPath
				},
				// 深度观察监听
				deep: true,
			},
		},
		mounted() {
			window.addEventListener("scroll", this.scrolling);
			let activedUrl = localStorage.getItem('activedUrl')||'';
			if(activedUrl){
				this.activedUrl = activedUrl
			}
			
		},
		methods:{
			toNav(data){
				localStorage.setItem('headClick',1);
				localStorage.setItem('activedUrl',data.url);
				this.activedUrl = data.url
				this.$router.push(data.url);
				this.isshowMobleNav = false;
			},
			scrolling() {
				// 滚动条距文档顶部的距离
				let scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				setTimeout(()=>{
					if(this.activedUrl=='/'){
						if(scrollTop>100){
							this.isSCroller = true;
							
							
							
						}else{
							
							this.isSCroller = false;
						}
					}
					
					
				},100)
			
			}
			
		}
	}
</script>
<style>
	
	.mobileNav_modal{
		position: fixed;
		width:100%;
		height:100vh;
		z-index: 1991;
		top:0;
		display: none;
		background-color: rgba(0,0,0,.2);
	}
	.mobileNavModalActive{  
		display: block!important;
	} 
	.NavList_ul{
		margin-top:200px;
	}
	.mobileNavList{
		width:60%;
		opacity: 0;
		z-index: 8999;
		right:-60%; 
		height:100vh;
		background-color: #222;
		position: fixed;
		transition: all .4s;
		top:0;
	}
	.mobileNavList li{
		font-size: 18px;
		color: #fff;
		font-size: 18px;
		color: #fff;
		text-align: center;
		line-height: 52px;
	}
	.mobileNavList li.active{
		color: red;
	}
	.deleteNav{
		position: fixed;
		width:24px;
		height:24px;
		right:16px;
		top:20px;
	}
	.MobleNavActive{
		opacity: 1;
		right:0;
		transition: all .4s;
	}
	.mobelNav{
		display:none;
		position: relative;
	}
	.mobelNav .deleteImgs{
		width:28px;
		height:28px;
		position: absolute;
		top:-11px;
		right:1px;
	}
	.lineBox .line{
		width:20px;
		height:1px;
		background-color: #666;
		margin-top:5px;
	}
	
	.homeheaderNav li{
		color: #fff!important;
		opacity: 0.86;
	}
	.homeheaderNav li::after{
		content: '';
		position: absolute;
		left: 0;
		width: 0;
		height: 1px;
		bottom:-9px;
		transition: all 0.4s ease-out 0s;
		background-color: #fff!important;
		opacity: 0;
	}
	.homeheaderNav li:hover{
		transition: all 0.4s ease-out 0s;
		color: #fff;
		opacity: 1;
	}
	.homeheaderNavback li{
		color: #000;
	}
	.clogo{
		width:50px;
	}
	.resetheight{
		background-color: #fff; 		box-shadow: 0px 2px 4px rgba(0,0,0,.2);
	}
	.resetwidth{
		width: 93%!important;
	}
	.navMian{
		width: 100%;
		height: 70px;
		transition: all 0.2s ease-out 0s;
		position: fixed; 
		z-index: 999;
		/* background-color: #fff; */
		/* box-shadow: 0px 2px 4px rgba(0,0,0,.2); */
		top: 0;
	}
	.headerNav{
		display: flex;
		width: 90%;
		margin:0 auto;	
		transition: all 0.2s ease-out 0s;
		justify-content: space-between;
		align-items: center;
	}
	.navBox{
		display: flex;
	}
	.navBox li{
		margin: 0 20px;
		cursor: pointer;
		font-size: 15px;
		color: #666;
		position: relative;
		transition: all 0.4s ease-out 0s;
	}
	
	.navBox li:hover::after{
		width: 100%;
		transition: all 0.4s ease-out 0s;
		bottom:-9px;
		opacity: 1;
	}
	.navBox .active{
		color: red;
	}
</style>