<template>
	<div>
		<div id="mymap" style="width:100%;height:400px"></div>
		<div class="width80 margin0Auto">
			<p class="aboutTitle">联系我们</p>
			<p class="fuwenbtxt" v-html="info.content_text"></p>
			<div class="kefBox" v-if="info">
				<el-row :gutter="1" class="row-bg" style="width: 100%;">
					<el-col :xs="24" :sm="24" :md="8" :lg="8">
						<div class="kItem">
							<p class="k1">咨询热线</p>
							<p class="k2">{{info.consultation_hotline}}</p>
						</div>
					</el-col>
					<el-col :xs="24" :sm="24" :md="8" :lg="8">
						<div class="kItem">
							<p class="k1">地址</p>
							<p class="k2">{{info.company_address}}</p>
						</div>
					</el-col>
					<el-col :xs="24" :sm="24" :md="8" :lg="8">
						<div class="kItem">
							<p class="k1">邮箱</p>
							<p class="k2">{{info.company_email}}</p>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<freeEvaluat></freeEvaluat>
		<Footer></Footer>
	</div>
</template>

<script>
	import {
		get_about_us
	} from "@/api/apis";
	import Footer from '@/components/footer'
	import freeEvaluat from '@/components/freeEvaluat'
	
	
	export default {
		data() {
			return {
				map: null,
				info: {}
			}
		},
		components: {
			Footer,
			freeEvaluat
		},
		mounted() {
			window.scrollTo(0,0) 
			this.indexData();
		},
		methods: {
			openWind() {

			},
			async indexData() {
				let {
					data
				} = await get_about_us();
				this.info = data.data;

				let lng = [];
				lng[0] = Number(this.info.lng);
				lng[1] = Number(this.info.lat)
				var map = new AMap.Map('mymap', {
					resizeEnable: true, //是否监控地图容器尺寸变化
					zoom: 15, //初始化地图层级
					center: lng //初始化地图中心点
				});
				let marker = new AMap.Marker({
					position: lng,
					map: map
				});


				map.add(marker)
				
				let infoWindow;
				var info = [];
				info.push("地址 : " + this.info.company_address + "");
				infoWindow = new AMap.InfoWindow({
					content: info.join("<br/>") 
				});
				infoWindow.open(map, map.getCenter());

			},

		}
	}

</script>

<style>
	.amap-marker-label {
		border: 1px solid #ccc;
		border-radius: 10px;
		padding: 2px;
		font-size: 12px;
		color: #fff;

	}

	.kefBox {
		display: flex;
		margin: 40px 0;
	}

	.kefBox .kItem {
		flex: 1;
		margin-top: 30px;
		padding-bottom: 30px;
	}

	.kefBox .k1 {
		font-size: 19px;
		font-weight: bold;
		color: rgba(51, 51, 51, .9);
	}

	.kefBox .k2 {
		margin-top: 6px;
		font-size: 20px;
		color: rgba(51, 51, 51, .9);
	}

	.aboutTitle {
		color: #333333;
		font-size: 29px;
		text-align: center;
		padding: 40px 0;
		padding-bottom: 30px;
	}

	.fuwenbtxt {
		line-height: 30px;
		font-size: 14px;
		color: rgba(51, 51, 51, .9);
	}

</style>

