import Vue from 'vue'
import Router from 'vue-router'

import Index from '@/page/Index'
import caseIntroduct from '@/page/caseIntroduct'
import About from '@/page/About'

import companyProfil from '@/page/companyProfil'
import detail from "@/components/detail";
import productService from '@/page/productService'


// // 解决重复跳转的警告问题
// const originalPush = Router.prototype.push;
// Router.prototype.push = function push(location) {
// 	return originalPush.call(this, location).catch((err) => err);
// };

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'Index',
            component: Index,
			meta: {			  keepAlive: true // 需要被缓存			}
        },
		{
		    path: '/caseIntroduct',
		    name: 'caseIntroduct',
		    component: caseIntroduct,
			meta: {
			   keepAlive: true
			}
		},
        {
            path: '/About',
            name: 'About',
            component: About,
			meta: {
			   keepAlive: false
			}
        },
		{
		    path: '/companyProfil',
		    name: 'companyProfil',
		    component: companyProfil,
			meta: {
			   keepAlive: true
			}
		},
       
		{
		    path: '/detail',
		    name: 'detail',
		    component: detail,
			meta: {
			   keepAlive: false
			}
		},
		{
		    path: '/productService',
		    name: 'productService',
		    component: productService,
			meta: {
			   keepAlive: false
			}
		}
		

    ]
})
