<template>
	<div>
		<img class="reschouImg" :src="IndexData.large" alt="">
		
		<p class="aboutTitle at">服务流程</p>
		<div class="Serviceprocess pc_ervice mobleiWidth">
			<div v-for="(data,index) in IndexData.service_process" style="display: flex;">
				<div class="sItem" >
					<img class="processImg" :src="data.img" alt="">
					<p>{{data.name}}</p>
				</div>
				<img class="jiantArrow" v-if="index!=IndexData.service_process.length - 1 " src="@/assets/jiantArrow.png" alt="">
			</div>
		</div>
		<p class="aboutTitle at wow fadeInUp" style="padding-bottom: 20px;">客户案列</p>
		<div class="width80 margin0Auto mobleiWidth">
			<div class="caseBoxList">
				<el-row :gutter="30" class="row-bg" style="width: 100%;">
					<el-col :xs="24" :sm="12" :md="12" :lg="8" v-for="(data,index) in caseList" :key="index">
						<div class="caseChilden wow fadeInUp" @click="toDetail(data.id)"  :data-wow-delay="'0.'+index+'s'">
							<!-- <img class="caseImg" :src="data.cover" alt=""> -->
							<div  class="caseImg"  :style="{backgroundImage:'url('+data.cover+')'}"></div>
							<div class="proCase">
								<div>
									<p class="mti">{{data.name}}</p>
									<p class="fti">{{data.desc}}</p>
								</div>
								<img class="iconjiantou" src="@/assets/iconjiantou.png" alt="">
							</div>
						</div>
					</el-col>
				</el-row>
				<!-- ani_openDtween -->
				<div class="paginat_box">
					<el-pagination
					      @current-change="handleCurrentChange"
					      :current-page="pageInfo.current_page"
					      :page-size="pageInfo.per_page"
					      layout="total, prev, pager, next"
					      :total="pageInfo.total">
					</el-pagination>
				</div>
			</div>
		</div>
		<freeEvaluat></freeEvaluat>
		<Footer></Footer>
	</div>
</template>

<script>
	import Footer from '@/components/footer'
	import freeEvaluat from '@/components/freeEvaluat'
	
	import WOW from "wow.js";
	import "wow.js/css/libs/animate.css";
	
	import {
		get_service_detail,
		get_home_page_data
	} from "@/api/apis";
	export default{
		components: {
			Footer,
			freeEvaluat
		},
		data(){
			return{
				perviceInfoData:{},
				caseList:[],
				currentPage:1,
				pagesize:3,
				totalNumber:10,
				IndexData:{},
				cid:'',
				pageInfo:{},
				anleilist: [{
						url: 'https://resources.jsmo.xin/templates/upload/17040/201909/1567492725422.jpg'
					},
					{
						url: 'https://resources.jsmo.xin/templates/upload/17040/201909/1567492219598.jpg'
					},
					{
						url: 'https://resources.jsmo.xin/templates/upload/17040/201909/156749231038.jpg'
					},
					{
						url: 'https://resources.jsmo.xin/templates/upload/17040/201909/1567492725422.jpg'
					},
					{
						url: 'https://resources.jsmo.xin/templates/upload/17038/201909/1567418222241.jpg'
					},
					{
						url: 'https://resources.jsmo.xin/templates/upload/17040/201909/1567492725422.jpg'
					}
				]
			}
		},
		beforeRouteEnter(to, from, next){
			next();
			if(from.name === 'Index'||from.name === 'companyProfil') { 
			  window.scrollTo(0,0) 
			} 
		},
		
		mounted() {
			// window.scrollTo(0,0) 
			let id = this.$route.query.value;
			this.cid = this.$route.query.value;
			this.indexData();
		},
		 watch:{
		        $route:{
		            handler(newRouter){
		                
		            },
		            immediate: true
		        }
		},
		methods:{
			handleCurrentChange(page){
				this.currentPage = page;
				this.indexData();
			},
			toDetail(value){
				this.$router.push({path:'/detail',query: {value:value}})
			},
		
			async indexData() {
				let {
					data
				} = await get_service_detail({
					category_id:this.cid,
					page:this.currentPage
				});
				
				this.IndexData = data.data;
				this.pageInfo = this.IndexData.case_list;
				this.caseList = this.IndexData.case_list.data;
				this.$nextTick(() => {
					let wow = new WOW({
						boxClass: "wow", // animated element css class (default is wow)
						animateClass: "animated", // animation css class (default is animated)
						offset: 0, // distance to the element when triggering the animation (default is 0)
						mobile: true, // trigger animations on mobile devices (default is true)
						live: false, // act on asynchronously loaded content (default is true)
						callback: function(box) {
							
							// the callback is fired every time an animation is started
							// the argument that is passed in is the DOM node being animated
						}
					});
					wow.init()
				});
			},
		}
	}
</script>

<style>
	@import url("../style/main.css");
	.Serviceprocess{
		display: flex;
		justify-content: center;
	}
	.el-pager li{
		border: 1px solid #f1f1f1;
		margin: 0 6px!important;
	}
	.Serviceprocess p{
		margin-top: 7px;
		font-size: 14px;
	}
	.Serviceprocess .processImg{
		width:78px;
		height:78px
	}
	.Serviceprocess .sItem{
		text-align: center;
	}
	.Serviceprocess .sItem p{
		font-size: 16px;
	}
	.jiantArrow{
		width:36px;
		height:20px;
		margin:0 14px;
		margin-top: 23px;
	}
	.qualificatCom{
		width:90%;
		margin:0 auto;
	}
	.qualificatCom .rongziImg{
		width:100%;
		height:300px;
		padding: 15px 0;
	}
	.qualificatBox{
		width:100%;
		background: #EFF3F7;
	}
	.aboutTitle{
		color: #333333;
		font-size: 29px;
		text-align: center;
		padding: 40px 0;
		padding-bottom: 70px;
	}
	.at{
		margin-top: 83px;
	}
	.certificateList{
		display: flex;
		flex-wrap: wrap;
		margin-top: 30px;
	}
	.certificateList .ceItem{
		width:33.3%;
	}
	.certificateList  .ceBox{
		text-align: center;
		display: table;
	}
	.certificateList .num{
		color: #333333;
		font-size: 26px;
	}
	.certificateList .cname{
		color: #999999;
		font-size: 12px;
		margin-top: 6px;
	}
	.introductRow{
		padding: 50px 0;
	}
	
	
	.introInfo{
		padding-left: 30px; 
	}
	.introInfo .ti1{
		font-size: 15px;
		color: #444;
	}
	.introInfo .ti2{
		font-size: 20px;
		color: #333333;
		margin-top:8px
	}
	.introInfo .ti3{
		font-size: 14px;
		color: #999;				
		line-height: 24px;
		margin-top:13px
	}
</style>