<template>
	<div>
		<div class="kehu_box" v-if="info.config_data&&info.config_data.consultation_hotline">
			<div class="kItem">
				<div class="xtxt">{{info.config_data.consultation_hotline}}</div>
				<img class="dimg" src="@/assets/dianhua.png" alt="">
			</div>
			<div class="kItem">
				<div class="xtxt">{{info.config_data.customer_wechat}}</div>
				<img class="dimg" src="@/assets/weixin.png" alt="">
			</div>
			<div class="kItem" :style="{'border-bottom':!isshowTop?0:'1px solid rgba(255,255,255,.3)'}" @click="isSubModal = true">
				<div class="xtxt">咨询客服</div>
				<img class="dimg" src="@/assets/kefu.png" alt="">
			</div>
			<div class="kItem" style="border-bottom:0" v-if="isshowTop" @click="scrollToTop">
				<div class="xtxt">返回顶部</div>
				<img class="dimg" src="@/assets/fanhui.png" alt="">
			</div>
		</div>
		<div class="kehu_box_modal" v-if="isSubModal">
			<div class="content">
				<img src="@/assets/deleteImg.png"  @click="close" class="deleteImg" alt="">
				<div class="freeForm">
					<p class="frerTi" style="text-align: center;">免费评估需求，获取报价方案</p>
					<div class="formItem">
						<input type="text" class="ipt" v-model="paramInfo.company" placeholder="请输入您的公司名称（选填）">
					</div>
					<div class="formItem">
						<input type="text" class="ipt" v-model="paramInfo.name" placeholder="请输入您的称呼">
						<span class="bitian"  v-if="paramInfo.name.length==0">（必填）</span>
					</div>
					<div class="formItem">
						<input type="text" class="ipt" v-model="paramInfo.phone" placeholder="请输入手机号码">
						<span class="bitian"  v-if="paramInfo.phone.length==0">（必填）</span>
					</div>
					<div class="formItem">
						<!-- <input type="text" class="ipt" v-model="paramInfo.content" placeholder="请输入您的需求概述（选填）"> -->
						
						<textarea style="background-color: rgba(238, 238, 238, 1);" name="" id="" cols="10" rows="3" v-model="paramInfo.content" class="textarea"  placeholder="请输入您的需求概述（选填）" autosize></textarea>
						
					</div>
					
					<div style="height: 10px;text-align: center;color: red;">{{msg}}</div>
					<div class="nowAppointment" @click="sub">
						立即预约
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		get_config_data,
		set_needs
	} from "@/api/apis";
	export default{
		data(){
			return{
				info:{
					config_data:{}
				},
				isshowTop:false,
				isSubModal:false,
				msg:'',
				paramInfo:{
					company:'',
					name:'',
					phone:'',
					content:''
				}
			}
		},
		mounted(){
			window.addEventListener("scroll", this.scrolling);
			this.indexData();
		},
		methods:{
			close(){
				this.isSubModal = false;
				this.paramInfo.company = '';
				this.paramInfo.name = '';
				this.paramInfo.phone = '';
				this.paramInfo.content = '';
			},
			openRef(){
				this.isSubModal = true;
			},
			async sub(){
				if(!this.paramInfo.name){
					this.msg = '请输入您的称呼!'
					return false
				}
				if(!this.paramInfo.phone){
					this.msg = '请输入手机号!'
					return false
				}
				if(this.paramInfo.phone){
					var regPhone = /^1[3456789]{1}\d{9}$/; //手机正则
					if(!regPhone.test(this.paramInfo.phone)){
						this.msg = '请输入正确的手机号!'
					}
				}
				this.msg = '';
				let {
					data
				} = await set_needs(this.paramInfo);
				
				if(data.code==1){
					this.$message({
						message: '提交成功，我们尽快和您联系！',
						type: "success",
					});
					this.paramInfo.company = '';
					this.paramInfo.name = '';
					this.paramInfo.phone = '';
					this.paramInfo.content = '';
				}else{
					
					this.$message({
						message: data.msg,
						type: "error",
					});
				}
			},
			async indexData() {
				let {
					data
				} = await get_config_data();
				this.info = data.data;
			},
			scrollToTop() {
				let height = window.screen.availHeight - 150;
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: "smooth"
				});
			},
			scrolling() {
				// 滚动条距文档顶部的距离
				let scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
					
				
				setTimeout(()=>{
					if(scrollTop>330){
						this.isshowTop = true;
					}else{
						this.isshowTop = false;
					}
				},600)
			
			},
		}
	}
</script>

<style>
	@import url("../style/main.css");
	.deleteImg{
		position: fixed;
		width:30px;
		height:30px;
		top:10px;
		right:10px;
		z-index: 9;
		cursor: pointer;
	}
	.deleteImg:hover{
		transition: all .5s;
		transform: rotate(-90deg);
	}
	.kehu_box_modal{
		position: fixed;
		width:100%;
		height:100vh;
		top:0;
		left:0;
		z-index:67;
		background-color: rgba(0,0,0,.4);
	}
	.kehu_box_modal .content{
		position: fixed;
		width:500px;
		top:50%;
		background-color: #fff;
		left:50%;
		transform: translate(-50%,-50%);
	}
	.kehu_box_modal .ipt{
		background-color: rgba(238, 238, 238, 1);
	}
	.kehu_box_modal  .formItem{
		margin: 0 auto;
		margin-bottom: 20px;
	}
	.kehu_box_modal,.nowAppointment {
		margin: 0 auto;
	}
	.kehu_box_modal .nowAppointment{
		margin-top:30px;
		margin-bottom: 40px;
	}
</style>